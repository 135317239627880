<div class="dvb-schulung-modellierung-kita">
    <h2 transloco="SCHULUNG.MODELLIERUNG_KITA.TITLE"></h2>
    <h3 transloco="SCHULUNG.ABLAUF" class="normal-abstand-top"></h3>
    <ul class="floating-text no-padding normal-abstand-top">
        <li transloco="SCHULUNG.MODELLIERUNG_KITA.STEPS.KITA_EROEFFNEN"></li>
        <li transloco="SCHULUNG.MODELLIERUNG_KITA.STEPS.STAMMDATEN_BEARBEITEN"></li>
        <li transloco="SCHULUNG.MODELLIERUNG_KITA.STEPS.GRUPPE_ERSTELLEN_ANPASSEN"></li>
        <li transloco="SCHULUNG.MODELLIERUNG_KITA.STEPS.PLATZART_ERSTELLEN"></li>
        <li transloco="SCHULUNG.MODELLIERUNG_KITA.STEPS.KITA_KONFIGURIEREN"></li>
        <li transloco="SCHULUNG.MODELLIERUNG_KITA.STEPS.BETREUUNGSFAKTOREN_ERSTELLEN"></li>
        <li transloco="SCHULUNG.MODELLIERUNG_KITA.STEPS.VEREINBARUNGSKONFIG_LAYOUT_ANPASSEN"></li>
    </ul>
</div>
