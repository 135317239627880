<div class="dvb-schulung-eintritt-bis-austritt">
    <h2 transloco="SCHULUNG.EINTRITT_BIS_AUSTRITT.TITLE"></h2>
    <h3 transloco="SCHULUNG.ABLAUF" class="normal-abstand-top"></h3>

    <ul class="floating-text no-padding normal-abstand-top">
        <li transloco="SCHULUNG.EINTRITT_BIS_AUSTRITT.STEPS.NEUER_BENUTZER_ANLEGEN"></li>
        <li transloco="SCHULUNG.EINTRITT_BIS_AUSTRITT.STEPS.ABLOESUNG_MIGRATION"></li>
        <li transloco="SCHULUNG.EINTRITT_BIS_AUSTRITT.STEPS.ARBEITEN_MIT_SUCHE"></li>
        <li transloco="SCHULUNG.EINTRITT_BIS_AUSTRITT.STEPS.FAVORITEN_AUFGABENLISTE"></li>
        <li transloco="SCHULUNG.EINTRITT_BIS_AUSTRITT.STEPS.KIND_PROFIL_ANSEHEN"></li>
        <li transloco="SCHULUNG.EINTRITT_BIS_AUSTRITT.STEPS.KITA_PROFIL_ANSEHEN"></li>
        <li transloco="SCHULUNG.EINTRITT_BIS_AUSTRITT.STEPS.ARBEITEN_MIT_BELEGUNGSLISTE"></li>
        <li transloco="SCHULUNG.EINTRITT_BIS_AUSTRITT.STEPS.KINDER_ZUWEISEN"></li>
        <li transloco="SCHULUNG.EINTRITT_BIS_AUSTRITT.STEPS.BERECHTIGUNGEN_BETREUER_VERWALTER"></li>
    </ul>
</div>
